// import { Error404 } from 'ui/components/pages/error404/error404';
// import { graphql } from 'gatsby';

// export const query = graphql`
//   query Error404Query {
//     site {
//       siteMetadata {
//         ...SiteMeta
//       }
//     }
//   }
// `;

// export default Error404;

import * as React from 'react';

const Error404 = () => <div>404!</div>;

export default Error404;
